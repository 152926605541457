import React, { useRef } from "react";
import { useBase } from "../../contexts/BaseContext";
import "./Page4.css";
import StripePayment from "../Components/StripePayment";
import { useAdmin } from "./AdminContext";

const Page4 = () => {
  const {
    name, setName,
    phone, setPhone,
    email, setEmail,
    country, setCountry,
    city, setCity,
    postalCode, setPostalCode,
    address, setAddress,
    taj, setTaj,
    paymentMethod, setPaymentMethod,makeReservationAdmin,
    selectedDate,selectedTime,selectedService
  } = useBase();

  const {intNum} = useAdmin();

   // Function to format date as a readable text
   const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('hu-HU', options);
  };

  const stripeRef = useRef(null);

  const handleNoneStripeSubmit = (e) => {
    makeReservationAdmin(intNum,selectedDate,selectedTime);
  };

  return (
    <div className="page">
      <div className="containerpay">
        <div className="left-column">
          <h2>Az Ön adatai</h2>
          <div className="inputs-container">
            <div className="input-group">
              <label>Név</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Telefonszám</label>
              <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Email</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Ország</label>
              <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Város</label>
              <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Irányítószám</label>
              <input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Cím</label>
              <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
            </div>
            <div className="input-group">
              <label>TAJ szám (opcionális)</label>
              <input type="text" value={taj} onChange={(e) => setTaj(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="right-column">
          <h2>Foglalási adatok</h2>
          <p>Kezelés típusa: {selectedService}</p>
          <p>Dátum: {formatDate(selectedDate)}</p>
          <p>Időpont: {selectedTime}</p>
        </div>
      </div>
      <button className="payment-button" onClick={() => handleNoneStripeSubmit()}>{"Foglalás"}</button>
    </div>
  );
};

export default Page4;