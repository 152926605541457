import React, { useState, useContext, useEffect } from "react";
import "./Page3.css";
import { useBase } from "../../contexts/BaseContext";
import { useAdmin } from "./AdminContext";

const Modal = ({ show, onClose, onSubmit,type,date }) => {
  const {addBefore,addAfter,getBefore,getAfter} = useAdmin();
  const [formData, setFormData] = useState({
    time: "",
    treatment: "",
    name: ""
  });

  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    if (type === "before") {
      const day = new Date(date).toISOString().split('T')[0]
      addBefore(day,formData.time,formData.treatment,formData.name);
    } else if (type === "after") {
      const day = new Date(date).toISOString().split('T')[0]
      addAfter(day,formData.time,formData.treatment,formData.name);
    }
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modclose-button" onClick={onClose}>x</button>
        <div className="modal-input">
          <label>Időpont:</label>
          <input
            type="text"
            name="time"
            value={formData.time}
            onChange={handleChange}
          />
        </div>
        <div className="modal-input">
          <label>Kezelés:</label>
          <input
            type="text"
            name="treatment"
            value={formData.treatment}
            onChange={handleChange}
          />
        </div>
        <div className="modal-input">
          <label>Név:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <button className="submit-button" onClick={handleSubmit}>Foglalás</button>
      </div>
    </div>
  );
};

const Page3 = ({ setTimeSelected }) => {
  const { selectedDate, setSelectedDate, firstOpen, setFirstOpen, nextPage, selectedTime, setSelectedTime, getKezLenght, getDisabledIntervals, disabledIntervals, getDisabledDates, disabledDates } = useBase();
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState("before");

  const handleNext = () => {
    setTimeSelected(selectedTime);
    nextPage();
  };

  useEffect(() => {
    getDisabledDates();
  }, [selectedDate]);

  useEffect(() => {
    getDisabledIntervals(new Date(selectedDate).toISOString().split('T')[0]);
  }, [selectedDate]);

  useEffect(() => {
    console.log(disabledIntervals);
  }, [disabledIntervals]);

  const getNextAvailableDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  };

  const getPrevAvailableDay = (date) => {
    const prevDate = new Date(date);
    prevDate.setDate(date.getDate() - 1);
    return prevDate;
  };

  const handleNextDay = () => {
    setFirstOpen(false);
    const nextDate = getNextAvailableDay(new Date(selectedDate));
    setSelectedDate(nextDate.toISOString().split('T')[0]);
    setSelectedTime(null);
  };

  const handlePrevDay = () => {
    const prevDate = getPrevAvailableDay(new Date(selectedDate));
    setSelectedDate(prevDate.toISOString().split('T')[0]);
    setFirstOpen(false);
    setSelectedTime(null);
  };

  const renderTimeSlots = () => {
    const slots = [];
    const day = new Date(selectedDate).getDay();
    const startHour = 8;
    const endHour = 19;
    const intervalMinutes = 10;

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += intervalMinutes) {
        const time = `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
        let isSelected = disabledIntervals.includes(time);
        if (getKezLenght() === 2 && day === 3 && time === "11:50") {
          isSelected = true;
        }
        slots.push(
          <div
            key={time}
            className={`time-slot ${isSelected ? "selected" : ""} ${
              selectedTime === time ? "active" : ""
            }`}
            onClick={() => !isSelected && setSelectedTime(time)}
          >
            {time}
          </div>
        );
      }
    }
    let isSelected2 = disabledIntervals.includes("20:00");
    slots.push(
      <div
        key={"20:00"}
        className={`time-slot ${isSelected2 ? "selected" : ""} ${
          selectedTime === "20:00" ? "active" : ""
        }`}
        onClick={() => !isSelected2 && setSelectedTime("20:00")}
      >
        {"20:00"}
      </div>
    );

    return slots;
  };

  const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
  const dayName = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"][new Date(selectedDate).getDay()];

  const handleModalSubmit = (data) => {
    console.log("Modal Data: ", data);
    // handle the submitted data here
  };
  const toggBefore = () => {
    setShowModal(true);
    setTypeModal("before");
  }
  const toggAfter = () => {
    setShowModal(true);
    setTypeModal("after");
  }

  return (
    <div className="page page-enter">
      <div className="date-navigation">
        <button className="nav-button" onClick={() => toggBefore()}>
          Korábbi
        </button>
        <button className="nav-button" onClick={handlePrevDay}>
          &#8592;
        </button>
        <div className="date-display">
          <h1>{formattedDate}</h1>
          <h2>{dayName}</h2>
        </div>
        <button className="nav-button" onClick={handleNextDay}>
          &#8594;
        </button>
        <button className="nav-button" onClick={() => toggAfter()}>
          Késöbbi
        </button>
      </div>
      <div className="time-slots">{renderTimeSlots()}</div>
      <button
        className="next-button"
        onClick={() => handleNext()}
        disabled={!selectedTime}
      >
        Következő
      </button>
      <Modal show={showModal} onClose={() => setShowModal(false)} onSubmit={handleModalSubmit} type={typeModal} date={selectedDate} />
    </div>
  );
};

export default Page3;