import React, { useEffect } from "react";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Kapcsolat from "./components/Kapcsolat";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section22 from "./components/Section2-2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import FooterComp from "./components/Footer";
import { useBase } from "../../contexts/BaseContext";

const MainPage = () => {
  const {fetchSettings} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  return (
    <body class="body">
        <div class="page-wrap">
            <Nav/>
            <Hero/>
            <Kapcsolat/>
            <Section1/>
            {<Section2/>}
            <Section22/>
            <Section3/>
            <Section4/>
            <FooterComp/>
        </div>
    </body>
  );
};

export default MainPage;