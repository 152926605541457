import React, { useEffect } from "react";
import NavRolunk from "./components/Nav";
import Section1Rolunk from "./components/Section1";
import Section2Rolunk from "./components/Section2";
import Section22Rolunk from "./components/Section22";
import FooterRolunk from "./components/Footer";
import { useBase } from "../../contexts/BaseContext";


const RolunkPage = () => {
  const {fetchSettings} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  return (
    <body class="body-2">
        <div class="rolunk-wrap">
            <NavRolunk/>
            <Section1Rolunk/>
            { <Section2Rolunk/> }
            <Section22Rolunk/>
            <FooterRolunk/>
        </div>
    </body>
  );
};

export default RolunkPage;