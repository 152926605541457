import React, { useEffect } from "react";
import NavAszf from "./components/Nav";
import AszfSection from "./components/AszfSection";
import FooterAszf from "./components/Footer";
import { useBase } from "../../contexts/BaseContext";

const AszfPage = () => {
  const {fetchSettings} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  return (
    <body class="body-4">
        <div class="aszf-wrap">
            <NavAszf/>
            <AszfSection/>
            <FooterAszf/>
        </div>
    </body>
  );
};

export default AszfPage;