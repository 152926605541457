import React, { useState } from "react";
import "./Page1.css";
import { useBase } from "../../contexts/BaseContext";

const services = [
  { id: 1, name: "Konzultáció (10p)" },
  { id: 2, name: "Fogszabályozás konzultáció (10p)" },
  { id: 3, name: "Fogkő leszedés (20p)" },
  { id: 4, name: "Gyógyszer rendelés (10p)" },
  { id: 5, name: "Tejfog vizsgálat (10p)" },
  { id: 6, name: "Fogékszer ragasztás (10p)" },
];

const Page1 = ({ setServiceSelected }) => {
  const {selectedService,setSelectedService,settings,fetchSettings} = useBase()
  
  

  const handleSelect = (service) => {
    setSelectedService(service);
    setServiceSelected(true); // Notify the parent component that a service has been selected
    fetchSettings();
  };

  return (  
    <div className="page page-enter">
    {settings.canReserve ?
      <>
      <h1 className="page-title">Kérem válasszon a szolgáltatások közül</h1>
      <ul className="service-list">
        {services.map((service) => (
          <li key={service.id} className="service-item">
            <label>
              <input
                type="radio"
                name="service"
                value={service.name}
                checked={selectedService === service.name}
                onChange={() => handleSelect(service.name)}
              />
              {service.name}
            </label>
          </li>
        ))}
      </ul>
    </>
    :
        <div className="unavailable-container">
          <h2>Az online foglalás jelenleg nem lehetséges</h2>
          <p>Látogass vissza később, vagy keress a következő telefonszámon:</p>
          <p className="phone-number">(+36)70/628-2159</p>
        </div>

    }
    </div>
  );
};

export default Page1;