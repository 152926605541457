import React, { useState, useContext, useEffect } from "react";
import "./Page3.css";
import { useBase } from "../../contexts/BaseContext";


const Page3 = ({ setTimeSelected }) => {
  const { selectedDate, setSelectedDate, firstOpen, setFirstOpen,nextPage,selectedTime, setSelectedTime,getKezLenght,getDisabledIntervals,disabledIntervals,getDisabledDates,disabledDates } = useBase();

  const handleNext = () => {
    setTimeSelected(selectedTime);
    nextPage();
  }
  useEffect(() => {
    getDisabledDates();
}, [selectedDate]);

  useEffect(() => {
    getDisabledIntervals(new Date(selectedDate).toISOString().split('T')[0]);
}, [selectedDate]); 
useEffect(() => {
  console.log(disabledIntervals)
}, [disabledIntervals]); 

  const getNextAvailableDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    const day = nextDate.getDay();
    const formattedDate = nextDate.toISOString().split('T')[0];

    // Check if the next date is disabled or not Wednesday/Friday
    if (disabledDates.includes(formattedDate) || (day !== 3 && day !== 5)) {
      return getNextAvailableDay(nextDate);
    }
    return nextDate;
  };

  const getPrevAvailableDay = (date) => {
    const prevDate = new Date(date);
    prevDate.setDate(date.getDate() - 1);
    const day = prevDate.getDay();
    const formattedDate = prevDate.toISOString().split('T')[0];

    // Check if the previous date is disabled or not Wednesday/Friday
    if (disabledDates.includes(formattedDate) || (day !== 3 && day !== 5)) {
      return getPrevAvailableDay(prevDate);
    }
    return prevDate;
  };

  const handleNextDay = () => {
    setFirstOpen(false);
    const nextDate = getNextAvailableDay(new Date(selectedDate));
    setSelectedDate(nextDate.toISOString().split('T')[0]); // Update the context or state with the new date
    setSelectedTime(null); // Reset the selected time when changing the date
  };

  const handlePrevDay = () => {
    const prevDate = getPrevAvailableDay(new Date(selectedDate));
    setSelectedDate(prevDate.toISOString().split('T')[0]); // Update the context or state with the new date
    setFirstOpen(false);
    setSelectedTime(null); // Reset the selected time when changing the date
  };


  const renderTimeSlots = () => {
    const slots = [];
    const day = new Date(selectedDate).getDay();
    const startHour = day === 3 ? 9 : 14;
    const endHour = day === 3 ? 11 : 18;
    const intervalMinutes = 10;

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += intervalMinutes) {
        const time = `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
        let isSelected = disabledIntervals.includes(time);
        if (getKezLenght() === 2 && day === 3 && time === "11:50") {isSelected = true;}
        slots.push(
          <div
            key={time}
            className={`time-slot ${isSelected ? "selected" : ""} ${
              selectedTime === time ? "active" : ""
            }`}
            onClick={() => !isSelected && setSelectedTime(time)}
          >
            {time}
          </div>
        );
      }
    }
    if (day === 5) {
      const time = `${19}:${10 < 10 ? `0${10}` : 10}`;
      const isSelected = disabledIntervals.includes(time);
      const time2 = `${19}:${0 < 10 ? `0${0}` : 0}`;
      const isSelected2 = disabledIntervals.includes(time2);
      const time3 = `${19}:${20 < 10 ? `0${20}` : 20}`;
      let isSelected3 = disabledIntervals.includes(time3);
      if (getKezLenght() === 2) {isSelected3 = true; }
      slots.push(
        <div
          key={time2}
          className={`time-slot ${isSelected2 ? "selected" : ""} ${
            selectedTime === time ? "active" : ""
          }`}
          onClick={() => !isSelected2 && setSelectedTime(time2)}
        >
          {time2}
        </div>
      );
      slots.push(
        <div
          key={time}
          className={`time-slot ${isSelected ? "selected" : ""} ${
            selectedTime === time ? "active" : ""
          }`}
          onClick={() => !isSelected && setSelectedTime(time)}
        >
          {time}
        </div>
      );
      slots.push(
        <div
          key={time3}
          className={`time-slot ${isSelected3 ? "selected" : ""} ${
            selectedTime === time3 ? "active" : ""
          }`}
          onClick={() => !isSelected3 && setSelectedTime(time3)}
        >
          {time3}
        </div>
      );
    }
    return slots;
  };

  const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
  const dayName = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"][new Date(selectedDate).getDay()];

  return (
    <div className="page page-enter">
      <div className="date-navigation">
        <button className="nav-button" onClick={handlePrevDay}>
          &#8592;
        </button>
        <div className="date-display">
          <h1>{formattedDate}</h1>
          <h2>{dayName}</h2>
        </div>
        <button className="nav-button" onClick={handleNextDay}>
          &#8594;
        </button>
      </div>
      <div className="time-slots">{renderTimeSlots()}</div>
      <button
        className="next-button"
        onClick={() => handleNext()}
        disabled={!selectedTime}
      >
        Következő
      </button>
    </div>
  );
};

export default Page3;