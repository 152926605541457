import React, { useEffect, useRef } from "react";

const Hero = () => {
  const elementsToObserve = useRef([]);
  const image10Ref = useRef(null);
  const image11Ref = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = 200; // max distance the images should move
      const image10 = image10Ref.current;
      const image11 = image11Ref.current;

      if (image10 && image11) {
        const translateY10 = Math.min(maxScroll, scrollPosition / 4);
        const translateY11 = Math.min(maxScroll, scrollPosition / 5);

        image10.style.transform = `translateY(${translateY10}px)`;
        image11.style.transform = `translateY(${translateY11}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="herosection">
      <div className="herobal">
        <div className="contentholder">
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="div-block-8 slide-down"
          >
            <p className="text-block-8">Katadent</p>
            <div className="div-block-9"></div>
          </div>
          <h1
            ref={(el) => elementsToObserve.current.push(el)}
            className="heading-2 slide-left"
          >
            FOGORVOSI ELLÁTÁS
            <br />
            A SZÉP MOSOLYÉRT
          </h1>
          <p
            ref={(el) => elementsToObserve.current.push(el)}
            className="paragraph slide-left-delayed"
          >
            A mosoly olyan szeretetsugár,amely mások
            <br /> arcán tükröződik vissza...
          </p>
          <a
            ref={(el) => elementsToObserve.current.push(el)}
            className="button w-button"
            href="/foglalas"
          >
            Időpont foglalás
          </a>
        </div>
        <img
          ref={image10Ref}
          src="images/60e45fd11f8182aeb6889f40_background-object-1-hero.svg"
          loading="lazy"
          alt=""
          className="image-10"
        />
        <img
          ref={image11Ref}
          src="images/60e45fd11f8182150c889f42_background-object-2-hero.svg"
          loading="lazy"
          alt=""
          className="image-11"
        />
      </div>
      <div className="herojobb">
        <img
          src="images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7.jpg"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 93vw, (max-width: 991px) 49vw, 2665.328125px"
          srcset="images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-500.jpg 500w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-800.jpg 800w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-1080.jpg 1080w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-1600.jpg 1600w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-2000.jpg 2000w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-2600.jpg 2600w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7-p-3200.jpg 3200w, images/3344FFB1-1FDE-458C-8EB2-9CB7594B38E7.jpg 4032w"
          alt=""
          className="image"
        />
        <div
          ref={(el) => elementsToObserve.current.push(el)}
          className="transitiondiv"
        ></div>
      </div>
    </div>
  );
};

export default Hero;