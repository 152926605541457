import React from "react";

const AszfSection = () => {
  
  return (
    <div>
        <div class="aszfwrap">
      <div class="heading1container">
        <h1 class="aszfheading1">Általános szerződési feltételek</h1>
      </div>
      <div class="aszfcontent">
        <div class="_1-content">
          <h1 class="szerzodofelekheading">1. Szerződő felek meghatározása</h1>
          <p class="_1-1text"><strong>1.1 Felhasználó: </strong>az a természetes személy, jogi személy vagy jogi személyiség nélküli szervezet, aki a weboldalt felkeresi és tartalmát megtekinti, megismeri, elolvassa. A látogató/érdeklődő (a továbbiakban együttesen <strong><em>Felhasználó</em></strong>) ezzel a magatartásával mint ráutaló magatartással elfogadja az ÁSZF-t.</p>
          <p class="_1-2text2"><strong>Szolgáltató: </strong>a weboldal, és a hozzá kapcsolódó szolgáltatások üzemeltetője</p>
        </div>
        <div class="nevstb">
          <p class="nev">Név: Dr. Németh Katalin egyéni vállalkozó</p>
          <p class="szekhely">Székhely: 8800 Nagykanizsa, Hunyadi u. 24.</p>
          <p class="emailimpresszum">Adószám: 60095851-1-40</p>
          <p class="honlapimpresszum">Nyilvántartási szám: 18203727</p>
          <p class="emailaszf">E-mail: info@katadent.hu</p>
        </div>
        <div class="_1-2content">
          <h1 class="aszftartalma">1.2. ÁSZF tartalma</h1>
          <p class="cegnev">Az Általános Szerződési Feltételek (továbbiakban ÁSZF) tartalmazzák a <strong>katadent.hu</strong> weboldalt (továbbiakban <strong><em>weboldal</em></strong>) látogatóként használó, termékek iránt érdeklődő vagy ajánlatkérő kapcsolati űrlapot kitöltő és továbbító személy, valamint a weboldal üzemeltetője (továbbiakban <strong><em>Szolgáltató</em></strong>) között szerződéses jogviszony általános feltételeit. A Felhasználó a weboldal bármely szolgáltatásának használatával (pl. az oldal böngészése, hírlevél igénylése – ha van -, kapcsolatfelvétel, ajánlatkérés, érdeklődés elküldése, hívás gomb használata, a továbbiakban együttesen: <strong><em>Szolgáltatás</em></strong>) elfogadja és magára nézve kötelezőnek ismeri el az ÁSZF-et, amellyel a felek között Szerződés jön létre.</p>
        </div>
        <div class="_1-3content">
          <h1 class="heading-31">1.3 Vonatkozó jogszabályok</h1>
          <p class="_1-3text1">Az ÁSZF a magyar jogszabályoknak, így különösen a Polgári Törvénykönyvről szóló 2013. évi V. törvény (Ptk.), a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII., valamint az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény rendelkezéseinek megfelelően értelmezendő. A vonatkozó jogszabályok kötelező rendelkezései a felekre külön kikötés nélkül is irányadók.</p>
          <p class="_1-3text2">A weboldal szolgáltatásainak használatával elektronikus úton megkötött szerződés jön létre a Szolgáltató és a Felhasználó között, melyet az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény, illetve a 17/1999. (II.5.) Kormányrendelet szabályoz.</p>
        </div>
        <div class="_2-content">
          <h1 class="aszfhatalyaheading">2. Az ÁSZF hatálya<br/></h1>
          <p class="aszfhatalya1">• Jelen ÁSZF 2020. január 31. napjától hatályos.</p>
          <p class="aszfhatalya2">• Az ÁSZF rendelkezéseit kell alkalmazni <strong>Dr. Németh Katalin egyéni vállalkozó</strong> weboldalának használatára, valamint kereskedelmi tevékenységére vonatkozóan</p>
          <p class="aszfhatalya3">• Az ÁSZF rögzíti a felek jogviszonyának általános feltételeit, a felek jogait és kötelezettségeit.</p>
          <p class="aszfhatalya4">• Az ÁSZF alanyai</p>
          <p class="aszfhatalya5">• Az ÁSZF egyrészről kiterjed Dr. Németh Katalin egyéni vállalkozóra, mint szolgáltatóra/kereskedőre, másrészről a <strong>katadent.hu</strong> weboldal látogatóira, felhasználóira.</p>
          <p class="aszfhatalya6">• A Szolgáltatásra vonatkozó szerződések nem minősülnek írásbeli szerződésnek, nem kerülnek iktatásra, kizárólag elektronikus formában kötődnek, a szerződések nyelve a magyar, magatartási kódexre nem utalnak. A szerződés létrejöttét az elektronikusan elmentett adatok és az igénybe vett szolgáltatásokra vonatkozó adatok igazolják</p>
          <p class="aszfhatalya7">• Az ÁSZF módosulását a Szolgáltató a weboldalon közzéteszi. A módosított ÁSZF a közzététel napjától kezdve hatályos. A módosítás időpontját követően a Felhasználó bármely Szolgáltatás használatával egyben elfogadja az ÁSZF módosítását is. Amennyiben a Felhasználó nem fogadja el a módosított feltételeket, úgy írásban, a módosítások hatályba lépését követő 8 napon belül rendkívüli felmondással felmondhatja a szerződését.</p>
        </div>
        <div class="_3-content">
          <h1 class="szolgaltatastargyaheading">3. A szolgáltatás tárgya, a felek jogai és kötelezettségei</h1>
          <p class="_3-text">Szolgáltató a honlapon hasznos információkat közöl szolgáltatásaival kapcsolatban, valamint lehetőséget biztosít fogászati rendelésének a weboldalon keresztül történő online bejelentkzésére.</p>
        </div>
        <div class="_3-1content">
          <h1 class="onlinebejelentkezesheading">3.1. Online bejelentkezés folyamata</h1>
          <p class="_3-1text">Az érdeklődő/látogató személy a weboldal “Online bejelntkezés” menüpontjában bejelentkezhet a rendelésekre vagy érdeklődhet problémájával kapcsolatban. A kapcsolati űrlapon a felhasználónak meg kell adnia nevét, e-mail címét, telefonszámát, üzenetét, majd a GDPR adatkezelési szabályzat és adatvédelmi nyilatkozat elolvasása és elfogadása után az <em>“Küldés”</em> gombra kattintva elküldheti üzenetét.</p>
        </div>
        <div class="_4-content">
          <h1 class="szellemiheading">4. Szellemi alkotások joga</h1>
          <p class="_4-text">Felhasználó tudomásul veszi, hogy a <strong>katadent.hu</strong> mint weboldal szerzői jogi műnek minősül, a weboldal tulajdonosa és a weboldalhoz kapcsolódó valamennyi szellemi alkotás jogosultja (továbbiakban: <strong><em>Tulajdonos</em></strong>): Dr. Németh Katalin egyéni vállalkozó<br/>A Tulajdonos fenntart magának minden jogot a weboldal, annak bármely részlete és az azon megjelenő tartalmak, valamint a weboldal terjesztésének tekintetében. Tilos a <strong>katadent.hu</strong> weboldalon megjelenő tartalmak vagy azok bármely részletének letöltése (többszörözése), más módon való felhasználása, elektronikus tárolása, feldolgozása és értékesítése a Tulajdonos írásos hozzájárulása nélkül.<br/>A <strong>katadent.hu</strong> weboldalról és annak adatbázisából bármilyen anyagot átvenni írásos hozzájárulás esetén is csak az adott weboldalra való hivatkozással lehet.A Tulajdonos fenntartja minden jogát a szolgáltatásának valamennyi elemére, domain nevére. Tilos továbbá a <strong>katadent.hu</strong> weboldal tartalmának, illetve egyes részeinek adaptációja vagy visszafejtése; a felhasználói azonosítók és jelszavak tisztességtelen módon történő létesítése; bármely olyan alkalmazás használata, amellyel a weboldal vagy annak bármely része módosítható.</p>
        </div>
        <div class="_5-content">
          <h1 class="adatvedelmiheading">5. Adatvédelmi rendelkezések</h1>
          <p class="_5-text1">A Szolgáltató köteles az adatkezeléssel kapcsolatos alábbi jogszabályok és ajánlások betartására:</p>
          <p class="_5-text2">Magyarország Alaptörvénye, VI. cikk<br/>A Polgári Törvénykönyvről szóló 2013. évi V. törvény2011. évi CXII. törvény az információs önrendelkezési jogról és az információszabadságról (Info tv.)<br/>a 2001. évi CVIII. törvény (Az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről),<br/>a 1998. évi VI. törvény – Az egyének védelméről a személyes adatok gépi feldolgozása során (Strasbourg 1981. január 28.)</p>
          <a href="https://www.katadent.hu/docs/adatkezelesi-tajekoztato.pdf" class="adatvedelmirtext">Az adatvédelmi rendelkezések itt olvashatóak &gt;&gt;</a>
        </div>
        <div class="_6-content">
          <h1 class="felmondasheading">6. Felmondás</h1>
          <p class="_6-text">Bármelyik fél jogosult a Szerződést azonnali hatállyal megszüntetni, ha a másik fél a Szerződésben foglaltakat súlyosan megsérti. A látogató bármikor lemondhatja a hírlevelek igénylését a hírlevél alján lévő lemondó linkre kattintva.</p>
        </div>
        <div class="_7-content">
          <h1 class="vismajorheading">7. Vis major</h1>
          <p class="_7-text1">Egyik fél sem felel a szerződésben foglalt kötelezettség teljesítéséért azon esetekben, amikor a felek érdekkörén kívül eső elháríthatatlan esemény merül fel. Ilyen körülménynek tekintendő például: természeti katasztrófa, tűz, áradás, hatóság rendelkezése, szükségállapot, lázadás, polgárháború, háború, sztrájk vagy hasonló munkabeszüntetés.</p>
          <p class="_7-text2">A Szolgáltató semmilyen módon nem felelős az alábbiakért, bármilyen módon is következtek be:<br/></p>
          <p class="_7-text3">• Bármilyen működési hiba a hálózaton, ami akadályozza weboldal normális működését és a hirdetés megtekintését</p>
          <p class="_7-text4">• A kommunikációs eszközök vagy a vonal meghibásodása</p>
          <p class="_7-text5">• Bármilyen elektronikus levél vagy adat elvesztése</p>
          <p class="_7-text6">• Bármely használt webes alkalmazás nem megfelelő működése</p>
          <p class="text-block-50">• Vírus, hackertámadás</p>
        </div>
        <div class="_8-content">
          <h1 class="heading-32">8. Egyéb rendelkezések</h1>
          <p class="text-block-45">A jelen ÁSZF-ben nem szabályozott kérdésekre a Magyarország jogszabályai, így különösen a Polgári Törvénykönyvről szóló 2013. évi V. tv., valamint szerzői jogról szóló 1999. évi LXXVI. tv. rendelkezései az irányadók.</p>
          <p class="text-block-46">Felek esetleges jogvitáikat elsődlegesen egyeztetés útján rendezik. Amennyiben ez nem vezet eredményre, úgy felek jogvitájukra nézve alávetik magukat hatáskörtől függően a Szolgáltató székhelye szerinti járásbíróság kizárólagos illetékességének. A jogvita elbírálására a Magyarország törvényei és jogszabályai az irányadók.</p>
          <p class="text-block-48">Nagykanizsa, 2020. január 31.</p>
          <p class="text-block-49">Dr. Németh Katalin</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AszfSection;