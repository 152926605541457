import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPagePc from './Pages/Landing/LandingPagePc';
import { BaseProvider, useBase } from './contexts/BaseContext';
import './css/webflow.css';
import './css/mainWeb.css';
import './css/normalize.css';
import MainPage from './Pages/Main/MainPage';
import RolunkPage from './Pages/Rolunk/RolunkPage';
import SzolgPage from './Pages/Szolgaltatasok/SzolgPage';
import AszfPage from './Pages/Aszf/AszfPage';
import ImpresszumPage from './Pages/Impresszum/ImpresszumPage';
import AdminLoginPage from './Pages/Admin/AdminLoginPage';
import { AdminProvider } from './Pages/Admin/AdminContext';

const App = () => {
  const width = window.innerWidth;
  const isDesktop = width >= 1024;
  
  return (
          <BaseProvider>
          <AdminProvider>
          <Helmet>
            <title>KataDent</title>
            <meta name="description" content="Fogorvosi ellátás, a szép mosolyért." />
            <meta property="og:title" content="KataDent" />
            <meta property="og:description" content="Fogorvosi ellátás, a szép mosolyért." />
            <meta property="og:image" content="https://katadent.hu/logo.png" />
            <meta property="og:url" content="https://katadent.hu" />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Router>
            <Routes>
              {isDesktop ? (
                <>
                  <Route path="/" element={<div style={{overflowX:"hidden"}}><MainPage/></div>} />
                  <Route path="foglalas" element={<div style={{overflowX:"hidden"}}><LandingPagePc/></div>} />
                  <Route path="szolgaltatasok" element={<div style={{overflowX:"hidden"}}><SzolgPage/></div>} />
                  <Route path="rolunk" element={<div style={{overflowX:"hidden"}}><RolunkPage/></div>} />
                  <Route path="aszf" element={<div style={{overflowX:"hidden"}}><AszfPage/></div>} />
                  <Route path="impresszum" element={<div style={{overflowX:"hidden"}}><ImpresszumPage/></div>} />
                  <Route path="admin" element={<div style={{overflowX:"hidden"}}><AdminLoginPage/></div>} />
                </>
              ) : (
                <>
                  <Route path="/" element={<div style={{overflowX:"hidden"}}><MainPage/></div>} />
                  <Route path="foglalas" element={<div style={{overflowX:"hidden"}}><LandingPagePc/></div>} />
                  <Route path="szolgaltatasok" element={<div style={{overflowX:"hidden"}}><SzolgPage/></div>} />
                  <Route path="rolunk" element={<div style={{overflowX:"hidden"}}><RolunkPage/></div>} />
                  <Route path="aszf" element={<div style={{overflowX:"hidden"}}><AszfPage/></div>} />
                  <Route path="impresszum" element={<div style={{overflowX:"hidden"}}><ImpresszumPage/></div>} />
                  <Route path="admin" element={<div style={{overflowX:"hidden"}}><AdminLoginPage/></div>} />
                </>
              )}
            </Routes>
            <div style={{height:"0vh",width:'0vw',overflow:'hidden'}}>
            </div>
          </Router>
          </AdminProvider>
          </BaseProvider>
  );
};

export default App;