import React, { useRef,useState } from "react";
import { useBase } from "../../contexts/BaseContext";
import "./Page4.css";
import StripePayment from "../Components/StripePayment";

const Page4 = () => {
  
  const {
    settings,
    name, setName,
    phone, setPhone,
    email, setEmail,
    country, setCountry,
    city, setCity,
    postalCode, setPostalCode,
    address, setAddress,
    taj, setTaj,
    paymentMethod, setPaymentMethod,
    selectedDate,selectedTime,selectedService,makeReservation
  } = useBase();

   // Function to format date as a readable text
   const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('hu-HU', options);
  };

  const stripeRef = useRef(null);

  const handleStripeSubmit = (e) => {
    console.log("STRIPE");
    //stripeRef.current.submit();
  };
  const handleNoneStripeSubmit = (e) => {
    makeReservation();
  };

  return (
    <div className="page">
    {settings.canReserve ?
      <>
      <div className="containerpay">
        <div className="left-column">
          <h2>Az Ön adatai</h2>
          <div className="inputs-container">
            <div className="input-group">
              <label>Név</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Telefonszám</label>
              <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Email</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Ország</label>
              <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Város</label>
              <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Irányítószám</label>
              <input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Cím</label>
              <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
            </div>
            <div className="input-group">
              <label>TAJ szám (opcionális)</label>
              <input type="text" value={taj} onChange={(e) => setTaj(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="right-column">
          <h2>Foglalási adatok</h2>
          <p>Kezelés típusa: {selectedService}</p>
          <p>Dátum: {formatDate(selectedDate)}</p>
          <p>Időpont: {selectedTime}</p>
          <div className="input-group">
            <h2>Fizetési mód</h2>
            <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
              {settings.canPay && <option value="online">Online fizetés</option>}
              <option value="onsite">Helyszíni fizetés</option>
            </select>
            {settings.canPay === 1 && paymentMethod === "online" &&  <StripePayment ref={stripeRef}/>}
          </div>
        </div>
      </div>
      <button className="payment-button" onClick={settings.canPay === 1 && paymentMethod === "online" ? () => handleStripeSubmit() : () => handleNoneStripeSubmit()}>{ settings.canPay === 1 && paymentMethod === "online" ? "Fizetés" : "Foglalás"}</button>
      </>
      :
      <div className="unavailable-container">
          <h2>Az online foglalás jelenleg nem lehetséges</h2>
          <p>Látogass vissza később, vagy keress a következő telefonszámon:</p>
          <p className="phone-number">(+36)70/628-2159</p>
        </div>
    }
    </div>
  );
};

export default Page4;