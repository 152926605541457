import React, { createContext, useContext, useState } from 'react';
import Page1 from '../Pages/Landing/Page1';
import Page2 from '../Pages/Landing/Page2';
import Page3 from '../Pages/Landing/Page3';
import Page4 from '../Pages/Landing/Page4';
import Page1A from '../Pages/Admin/Page1';
import Page2A from '../Pages/Admin/Page2';
import Page3A from '../Pages/Admin/Page3';
import Page4A from '../Pages/Admin/Page4';
import axios from 'axios';
import { toast } from 'react-toastify';

const BaseContext = createContext(null);

export const useBase = () => useContext(BaseContext);

export const BaseProvider = ({ children }) => {
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceID, setSelectedServiceID] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [isServiceSelected, setIsServiceSelected] = useState(false);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [firstOpen, setFirstOpen] = useState(true);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [taj, setTaj] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("online");
    const [disabledDates,setDisabledDates] = useState([]);
    const [disabledIntervals,setDisabledIntervals] = useState([]);
    const [predefinedToken,setpredefinedToken] = useState("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120")
    const [doneRes,setDoneRes] = useState("");
    const [navOpen,setNavOpen] = useState(false);
    const [foglalasON,setFoglalasOn] = useState(false);
    const [settings, setSettings] = useState({
      price1: 0,
      price2: 0,
      price3: 0,
      price4: 0,
      price5: 0,
      price6: 0,
      canReserve: 0,
      canPay: 0,
      holiday: 0,
      hStart: "",
      hEnd: ""
  });

  const fetchSettings = async () => {
      axios.get(`https://katadent.hu:41806/admin/getAdminSettings`, {
          headers: {
            Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          console.log('Response:', response);
          setSettings(response.data)
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
  };

    const pages = [<Page1 setServiceSelected={() => {}} />, <Page2 setDateSelected={() => {}} />, <Page3 />, <Page4 />];

    const pagesA = [<Page1A setServiceSelected={() => {}} />, <Page2A setDateSelected={() => {}} />, <Page3A />, <Page4A />];

    const nextPage = () => {
        if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
            setIsServiceSelected(false); // Reset the service selection state when moving to the next page
            setFirstOpen(true);
        }
    };

    const getKezLenght = () => {
        if (selectedService === "Fogkő leszedés (20p)") {
            return 2;
        } else {return 1;}
    }
    const getKezLenghtA = (serv) => {
      if (serv === "Fogkő leszedés (20p)") {
          return 2;
      } else {return 1;}
  }

    const getServPrice = () => {
      fetchSettings();

      if (selectedService === "Konzultáció (10p)") {return settings.price1}
      else if (selectedService === "Fogszabályozás konzultáció (10p)") {return settings.price2}
      else if (selectedService === "Fogkő leszedés (20p)") {return settings.price3}
      else if (selectedService === "Gyógyszer rendelés (10p)") {return settings.price4}
      else if (selectedService === "Tejfog vizsgálat (10p)") {return settings.price5}
      else if (selectedService === "Fogékszer ragasztás (10p)") {return settings.price6}
      else {return 0}
    }

    const getDisabledIntervals = (date) => {
        axios.get(`https://katadent.hu:41806/reservation/getIntervals?date=${date}`, {
            headers: {
              Authorization: `Bearer ${predefinedToken}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            setDisabledIntervals(response.data)
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    }
    const getDisabledDates = () => {
        axios.get(`https://katadent.hu:41806/reservation/getFullDays`, {
            headers: {
              Authorization: `Bearer ${predefinedToken}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            setDisabledDates(response.data)
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    }
    const checkResData = () => {
        let answer = true;
        if(selectedDate === null || selectedDate === "") {answer=false;}
        if(selectedTime === null || selectedTime === "") {answer=false;}
        if(selectedService === null || selectedService === "") {answer=false;}
        if(name === null || name === "") {answer=false;}
        if(phone === null || phone === "") {answer=false;}
        if(email === null || email === "") {answer=false;}
        if(postalCode === null || postalCode === "") {answer=false;}
        if(country === null || country === "") {answer=false;}
        if(city === null || city === "") {answer=false;}
        if(address === null || address === "") {answer=false;}
        if(taj === null || taj === "") {answer=false;}
        return answer;
    }
    const resetInputDatas = () => {
        setSelectedService(null);
        setAddress("");
        setCity("");
        setCountry("");
        setEmail("");
        setName("");
        setPhone("");
        setPostalCode("");
        setSelectedDate(null);
        setSelectedServiceID(null);
        setSelectedTime(null);
        setTaj("");
        setCurrentPage(0);
    }
    
    const makeReservation = () => {
        if (checkResData()) {
        const intervalLenght = getKezLenght();
        const dateStr = new Date(selectedDate).toISOString().split('T')[0];
        axios.get(`https://katadent.hu:41806/reservation/make?resDateString=${dateStr}&resStartIntervall=${selectedTime}&resIntervallLenght=${intervalLenght}&name=${name}&phone=${phone}&email=${email}&country=${country}&city=${city}&postalCode=${postalCode}&adress=${address}&tajNum=${taj}&resAllIntervall=${selectedService}`, {
            headers: {
              Authorization: `Bearer ${predefinedToken}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            resetInputDatas();
            setDoneRes("SUCCESS-NOPAY");
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
                setDoneRes("FAIL-NOPAY");
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
                setDoneRes("FAIL-NOPAY");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                setDoneRes("FAIL-NOPAY");
            }
          });
        }
    }
    const makeReservationAdmin = (intNum,date,time) => {
      if (name !== null && name !== "") {
      const dateStr = new Date(date).toISOString().split('T')[0];
      axios.get(`https://katadent.hu:41806/reservation/make?resDateString=${dateStr}&resStartIntervall=${time}&resIntervallLenght=${intNum}&name=${name}&phone=${phone}&email=${email}&country=${country}&city=${city}&postalCode=${postalCode}&adress=${address}&tajNum=${taj}&resAllIntervall=${selectedService}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          console.log('Response:', response);
          resetInputDatas();
          setDoneRes("SUCCESS-NOPAY");
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
              setDoneRes("FAIL-NOPAY");
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
              setDoneRes("FAIL-NOPAY");
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
              setDoneRes("FAIL-NOPAY");
          }
        });
      }
  }

  const deleteRes = async (id) => {
    axios.get(`https://katadent.hu:41806/admin/deleteRes?id=${id}`, {
        headers: {
          Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
          'Content-Type': 'application/json' // Ensure the correct Content-Type is set
        }
      })
      .then(response => {
        console.log('Response:', response);
        toast.success('Sikeres Időpont Törlés!');
      })
      .catch(error => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Payment Error:', error.response.data);
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
            toast.error('Sikertelen Időpont Törlés!');
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Payment Error:', error.request);
            toast.error('Sikertelen Időpont Törlés!');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
            toast.error('Sikertelen Időpont Törlés!');
        }
      });
};

    const contextValue = {
        selectedDate, setSelectedDate, selectedTime, setSelectedTime, firstOpen, setFirstOpen,selectedService, setSelectedService,selectedServiceID, setSelectedServiceID,
        currentPage, setCurrentPage, isServiceSelected, setIsServiceSelected, isDateSelected, setIsDateSelected,
        pages, nextPage,getKezLenght,pagesA,getKezLenghtA,makeReservationAdmin,navOpen,setNavOpen,getServPrice,
        name, setName, phone, setPhone, email, setEmail, country, setCountry, city, setCity, postalCode, setPostalCode,doneRes,setDoneRes,
        address, setAddress, taj, setTaj, paymentMethod, setPaymentMethod,getDisabledDates,disabledDates,getDisabledIntervals,disabledIntervals,makeReservation
        ,foglalasON,settings,fetchSettings,deleteRes
      };

    return <BaseContext.Provider value={contextValue}>{children}</BaseContext.Provider>;
};