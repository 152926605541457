import React, { useEffect, useRef } from "react";

const Section1 = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div>
      <div className="section-1">
        <div className="rolunkbal">
          <div className="contentholder">
            <div
              ref={(el) => elementsToObserve.current.push(el)}
              className="div-block-6"
            >
              <div className="text-block-7">Rólunk</div>
              <div className="div-block-7"></div>
            </div>
            <h1
              ref={(el) => elementsToObserve.current.push(el)}
              className="rolunkheroheading"
            >
              Szakképzett csapatunk készen <br />
              áll, hogy segítsen!
            </h1>
            <p
              ref={(el) => elementsToObserve.current.push(el)}
              className="rolunkleiras"
            >
              A Katadentnél elkötelezettek vagyunk pácienseink iránt,
              szakértelemmel és gondoskodással ápoljuk fogaikat.
            </p>
            <a
              ref={(el) => elementsToObserve.current.push(el)}
              href="/rolunk"
              className="rolunkgomb w-button"
            >
              Rólunk
            </a>
          </div>
        </div>
        <div className="rolunkjobb">
          <img
            src="images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a.jpg"
            loading="lazy"
            sizes="(max-width: 479px) 88vw, (max-width: 767px) 93vw, (max-width: 991px) 52vw, 45vw"
            srcSet="images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-500.jpg 500w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-800.jpg 800w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-1080.jpg 1080w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-1600.jpg 1600w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-2000.jpg 2000w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-2600.jpg 2600w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a-p-3200.jpg 3200w, images/CF8D6240-6858-49BF-851C-F4963B696A63_1_201_a.jpg 4570w"
            alt=""
            className="rolunkkep"
          />
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="transitiondiv"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Section1;