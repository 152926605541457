import React, { useEffect, useRef, useState } from "react";

const Section22Rolunk = () => {
  const elementsToObserve = useRef([]);
  const [w,setW] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setW(width);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="r-lunk-section1">
      <div className="rolunkcontent1">
        <div className="szoveg1">
          <div className="szoveg1bal">
            <div>
              <h1
                ref={(el) => elementsToObserve.current.push(el)}
                className="szoveg1heading"
              >
                Dr. németh katalin
              </h1>
              <p className="text-block-17">Fogorvos és fogszabályozó szakorvos</p>
            </div>
            <p className="paragraph-2">
              Dr. Németh Katalin fogorvos és fogszabályozó szakorvos vagyok.
              2005-ben végeztem a Pécsi Orvostudományi Egyetem Fogorvosi Szakán.
              Fogszabályozó szakorvosi vizsgámat 2008-ban szereztem meg
              Budapesten. <br />
              <br />
              Az elmúlt időszakban Kaposváron, a Herczeg Fogászaton dolgoztam
              közreműködőként. Jelenleg Nagykanizsán rendelek
              magánvállalkozóként, és részt veszek a városi TB által támogatott
              fogszabályozásban is. Szeretek az embereken segíteni, különösen a
              gyermekeken.
            </p>
          </div>
          <div className="szoveg1jobb">
            <img
              src="images/rolunkkep.png"
              loading="lazy"
              //sizes="(max-width: 767px) 100vw, (max-width: 991px) 300px, 252.75px"
              //srcSet="images/53361190_1314228155405809_3296795889724555264_n-p-500.jpg 500w, images/53361190_1314228155405809_3296795889724555264_n-p-800.jpg 800w, images/53361190_1314228155405809_3296795889724555264_n.jpg 1080w"
              alt=""
              className="szoveg1kep"
            />
          </div>
        </div>
        <div className="szoveg2">
          <div className="szoveg2bal">
            <img
              src="images/IMG_4289-1.jpg"
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 293.671875px, 247.421875px"
              srcSet="images/IMG_4289-1-p-500.jpg 500w, images/IMG_4289-1-p-800.jpg 800w, images/IMG_4289-1.jpg 1080w"
              alt=""
              className="image-6"
            />
          </div>
          <div className="szoveg2jobb">
            <div>
              <h1
                ref={(el) => elementsToObserve.current.push(el)}
                className="szoveg2heading"
              >
                Salamon bernadett
              </h1>
              <p className="text-block-38">Fogászati asszisztens</p>
            </div>
            <p className="paragraph-5">
              Salamon Bernadett a nevem, fogászati asszisztensként végeztem a
              Pécsi Szociális és Egészségügyi Szakképző Iskolában. <br />
              <br />
              Először Dr. Rejtő Attila privát rendelőjében dolgoztam
              asszisztensként. Főként szájsebészettel és implantológiával
              foglalkoztunk.
              <br />
              <br />
              2007 óta dolgozom a nagykanizsai TB által támogatott
              fogszabályozáson, és 2009 óta Dr. Németh Katalin
              magánrendelésén is.
            </p>
          </div>
        </div>
        <div className="elotteutanawrap">
          <h1
            ref={(el) => elementsToObserve.current.push(el)}
            className="elotteutanaheading"
          >
            Előtte - <span className="text-span">utána</span>
          </h1>
          <div className="elotteutana">
            <div className="elotteutanabal">
              <img
                src="images/IMG_4257.JPG"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 47vw, 32vw"
                srcSet="images/IMG_4257-p-500.jpg 500w, images/IMG_4257-p-800.jpg 800w, images/IMG_4257-p-1080.jpg 1080w, images/IMG_4257-p-1600.jpg 1600w, images/IMG_4257.JPG 1914w"
                alt=""
                className="image-16"
              />
            </div>
            <div className="elotteutanajobb">
              <img
                src="images/IMG_4256.JPG"
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 46vw, 31vw"
                srcSet="images/IMG_4256-p-500.jpg 500w, images/IMG_4256-p-800.jpg 800w, images/IMG_4256-p-1080.jpg 1080w, images/IMG_4256-p-1600.jpg 1600w, images/IMG_4256-p-2000.jpg 2000w, images/IMG_4256.JPG 2036w"
                alt=""
                className="szoveg3kep"
              />
            </div>
          </div>
          <div className="elotteutana">
            <div className="elotteutanabal" style={w<992?{width:"45vw",height:"25vw"}:{width:"29vw",height:"13vw"}}>
              <img
                src="images/elotte2.JPG"
                loading="lazy"
                alt=""
                className="image-16"
                style={w<992?{width:"45vw",height:"25vw"}:{width:"29vw",height:"13vw"}}
              />
            </div>
            <div className="elotteutanajobb" style={w<992?{width:"45vw",height:"25vw"}:{width:"29vw",height:"13vw"}}>
              <img
                src="images/utana2.JPEG"
                loading="lazy"
                alt=""
                className="szoveg3kep"
                style={w<992?{width:"45vw",height:"25vw"}:{width:"29vw",height:"13vw"}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section22Rolunk;