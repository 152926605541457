import React, { useEffect } from "react";
import NavSzolg from "./components/Nav";
import ContentSzolg from "./components/Content";
import FooterSzolg from "./components/Footer";
import { useBase } from "../../contexts/BaseContext";

const SzolgPage = () => {
  const {fetchSettings} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  return (
    <body class="body-3">
        <div class="szolgaltatasaink-wrap">
            <NavSzolg/>
            <ContentSzolg/>
            <FooterSzolg/>
        </div>
    </body>
  );
};

export default SzolgPage;