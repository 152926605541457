// AdminPage.js
import React from 'react';
import { useAdmin } from './AdminContext';
import './Admin.css';
import AdminList from './AdminList';
import AdminKeszlet from './AdminKeszlet';
import LandingPageAdmin from './LandingPagePc';
import AdminSettings from './AdminSettings';

const AdminPage = () => {
    const { logout,pageAdmin,setPageAdmin,napstat,name,setName,
            name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
            cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
            setNapStat,getToggles,hnum,setHnum,toggles,getColor,handleToggle,handleSubmitAdmin
    } = useAdmin();


    return (
        <div className="admin-container">
            <nav className="admin-navbar">
                <ul>
                    <li onClick={()=>setPageAdmin('nap')}>Admin Foglalás</li>
                    <li onClick={()=>setPageAdmin('list')}>Időpontok</li>
                    <li onClick={()=>setPageAdmin('settings')}>Beállítások</li>
                </ul>
                <button onClick={logout} className="logout-button">Logout</button>
            </nav>
            <div>
                {pageAdmin === "nap" && 
                    <LandingPageAdmin/>
                }
                {pageAdmin === "list" &&
                    <AdminList/>
                }
                {pageAdmin === 'settings' && <AdminSettings />}
            </div>
        </div>
    );
};

export default AdminPage;