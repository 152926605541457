import React, { useEffect, useRef } from "react";

const Section1Rolunk = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="section-1-copy">
      <div className="rolunkpagebal">
        <div className="contentholder">
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="div-block-6"
          >
            <p className="text-block-7">Rólunk</p>
            <div className="div-block-7"></div>
          </div>
          <h1
            ref={(el) => elementsToObserve.current.push(el)}
            className="rolunkheroheading"
          >
            Szakképzett csapatunk készen <br />
            áll, hogy segítsen!
          </h1>
          <p
            ref={(el) => elementsToObserve.current.push(el)}
            className="rolunkleiras"
          >
            A Katadentnél elkötelezettek vagyunk pácienseink iránt,
            szakértelemmel és gondoskodással ápoljuk fogaikat.
          </p>
        </div>
      </div>
      <div className="rolunkjobb-copy">
        <img
          src="images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a.jpg"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 93vw, 45vw" 
          srcset="images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-500.jpg 500w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-800.jpg 800w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-1080.jpg 1080w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-1600.jpg 1600w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-2000.jpg 2000w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a-p-2600.jpg 2600w, images/0D537925-F033-42D0-99C3-993676DC7860_1_201_a.jpg 3024w"
          alt=""
          className="rolunkkep-copy"
        />
        <div
          ref={(el) => elementsToObserve.current.push(el)}
          className="transitiondiv"
        ></div>
      </div>
    </div>
  );
};

export default Section1Rolunk;