import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faCalendar, faClock, faCheck } from '@fortawesome/free-solid-svg-icons';

const icons = [faTooth, faCalendar, faClock, faCheck];

const ProgressBar = ({ currentPage, setPage, totalPages }) => {
    return (
      <div className="progress-bar">
        {icons.map((icon, index) => (
          <div
            key={index}
            className={`progress-step ${index <= currentPage ? "active" : ""}`}
            onClick={() => setPage(index)}
          >
            <FontAwesomeIcon icon={icon} size="2x" className="icon" />
            {index < totalPages - 1 && <div className={`progress-connector ${index < currentPage ? "active" : ""}`}></div>}
          </div>
        ))}
      </div>
    );
  };
  
  export default ProgressBar;