import React from "react";


const ImpresSection = () => {
  
  return (
    <div>
        <div class="impresszumwrap">
      <div class="heading1container">
        <h1 class="aszfheading1">Impresszum</h1>
      </div>
      <div class="aszfcontent">
        <div class="honlapotuzemelteto">
          <h1 class="szerzodofelekheading">A honlapot üzemeltető és a tevékenységet végző vállalkozás neve:<br/></h1>
          <p class="kataegyenivalallalkozo">• Dr. Németh Katalin egyéni vállalkozó</p>
          <p class="szekhelyimpresszum">• Székhely: 8800 Nagykanizsa, Hunyadi u. 24.</p>
          <p class="adoszamimpresszum">• Adószám: 60095851-1-40</p>
        </div>
        <div class="elerhetosegiadatok">
          <h1 class="elerhetosegiadatokimpresszum">Elérhetőségi adatok:</h1>
          <p class="telefonimpresszum">Telefon: +36 (70) 628-2159</p>
          <p class="emailimpresszum">E-mail: info@katadent.hu</p>
          <p class="honlapimpresszum">Honlap: www.katadent.hu</p>
        </div>
        <div class="tarhelyadatok">
          <h1 class="tarhelyszolgaltatoadatai">Tárhelyszolgáltató adatai:<br/></h1>
          <p class="cegnev">• Cégnév: GMA Corporation</p>
          <p class="adoszam">• Adószám: 43925456-1-40</p>
          <p class="telefon">• Telefon: +36203536579</p>
          <p class="emailgma">• E-mail: contact@gmacorporation.com</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ImpresSection;