// AdminContext.js
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


const AdminContext = createContext(null);

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
    const [page, setPage] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [predefinedToken,setpredefinedToken] = useState("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120")
    const [pageAdmin,setPageAdmin] = useState("nap");
    const [name, setName] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [postalcode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [adress,setAdress] = useState('');
    const [country,setCountry] = useState('Magyarország');
    const [email, setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [comment,setComment] = useState('');
      const [order,setOrder] = useState("id");
      const [desc,setDesc] = useState(false);
      const [search,setSearch] = useState("");
      const [intNum,setIntNum] = useState(1);
      const [before,setBefore] = useState([]);
      const [after,setAfter] = useState([]);

    const login = (uname,pass) => {
        axios.get(`https://katadent.hu:41806/admin/login?username=${uname}&pass=${pass}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
            setIsLoggedIn(response.data)
          console.log('Response:', response);
          setpredefinedToken("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
    };

    const logout = () => {
        setIsLoggedIn(false);
    };
    
  

      const extractDate = (dateStr) => {
        if (dateStr !== null) {
            return dateStr.split('-').slice(0, 3).join('-');
        } else {return "";}
      };

      const addBefore = (day,time,treat,name) => {
        axios.get(`https://katadent.hu:41806/reservation/addBefore?time=${time}&day=${day}&kez=${treat}&name=${name}`, {
          headers: {
            Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          console.log('Response:', response);
          setpredefinedToken("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
    };

    const addAfter = (day,time,treat,name) => {
      axios.get(`https://katadent.hu:41806/reservation/addAfter?time=${time}&day=${day}&kez=${treat}&name=${name}`, {
        headers: {
          Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
          'Content-Type': 'application/json' // Ensure the correct Content-Type is set
        }
      })
      .then(response => {
        console.log('Response:', response);
        setpredefinedToken("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
      })
      .catch(error => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Payment Error:', error.response.data);
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Payment Error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
      });
  };

  const getBefore = (day) => {
    axios.get(`https://katadent.hu:41806/reservation/getBefore?day=${day}`, {
      headers: {
        Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
        'Content-Type': 'application/json' // Ensure the correct Content-Type is set
      }
    })
    .then(response => {
      console.log('Response:', response);
      setBefore(response.data);
      setpredefinedToken("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
    })
    .catch(error => {
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Payment Error:', error.response.data);
          console.error('Status:', error.response.status);
          console.error('Headers:', error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error('Payment Error:', error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
      }
    });
};

const getAfter = (day) => {
  axios.get(`https://katadent.hu:41806/reservation/getAfter?day=${day}`, {
    headers: {
      Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
      'Content-Type': 'application/json' // Ensure the correct Content-Type is set
    }
  })
  .then(response => {
    console.log('Response:', response);
    setAfter(response.data);
    setpredefinedToken("KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
  })
  .catch(error => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Payment Error:', error.response.data);
        console.error('Status:', error.response.status);
        console.error('Headers:', error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Payment Error:', error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
    }
  });
};

      

    
    // Context value that will be passed to consumers
    const contextValue = {
        isLoggedIn,setIsLoggedIn,login,logout,pageAdmin,setPageAdmin,predefinedToken,
        name, setName,
        postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
        totalPages, setTotalPages,intNum,setIntNum,
        order,setOrder,desc,setDesc,search,setSearch,page, setPage,
        addBefore,addAfter,getBefore,getAfter,before,after

    };

    return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};

