import React, { useEffect } from "react";
import NavImp from "./components/Nav";
import ImpresSection from "./components/ImpresSection";
import FooterImp from "./components/Footer";
import { useBase } from "../../contexts/BaseContext";


const ImpresszumPage = () => {
  const {fetchSettings} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  return (
    <body class="body-5">
        <div class="impresszumheadingwrap">
            <NavImp/>
            <ImpresSection/>
            <FooterImp/>
        </div>
    </body>
  );
};

export default ImpresszumPage;