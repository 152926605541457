import React, { useEffect, useRef } from "react";

const Section4 = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="section-4">
      <div className="section4bal">
        <div className="contentholder-copy">
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="miertmitextholder"
          >
            <p className="miertmitext-copy">FOGLALJON IDŐPONTOT</p>
          </div>
          <h1
            ref={(el) => elementsToObserve.current.push(el)}
            className="miertmiheading-copy"
          >
            cSAPATUNK KÉSZEN ÁLL,
            <br />
            hOGY SEGÍTSEN!
          </h1>
          <p
            ref={(el) => elementsToObserve.current.push(el)}
            className="miertmileiras-copy"
          >
            Foglalj időpontot gyorsan és egyszerűen online akár telefonálás nélkül!
          </p>
          <div className="div-block-10">
            <a
              ref={(el) => elementsToObserve.current.push(el)}
              style={{
                webkitTransform:
                  "translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                mozTransform:
                  "translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              }}
              href="/rolunk"
              className="miertmigomb-copy w-button"
            >
              Miért mi?
            </a>
            <a href="tel:+36706282159" className="hivasgomb w-inline-block">
              <div className="div-block-12">
                <img
                  src="images/call_100dp_FFFFFF_FILL0_wght400_GRAD0_opsz48.png"
                  loading="lazy"
                  alt=""
                  className="image-13"
                />
              </div>
              <div className="text-block-16">70/628-2159</div>
            </a>
          </div>
        </div>
      </div>
      <div className="section4jobb">
        <img
          src="images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a.jpeg"
          loading="lazy"
          width="Auto"
          sizes="(max-width: 767px) 100vw, (max-width: 991px) 45vw, 50vw"
          alt=""
          srcSet="images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-500.jpeg 500w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-800.jpeg 800w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-1080.jpeg 1080w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-1600.jpeg 1600w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-2000.jpeg 2000w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-2600.jpeg 2600w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a-p-3200.jpeg 3200w, images/E05A6041-59B3-4E4C-858A-045D21BBABD3_1_201_a.jpeg 3365w"
          className="image-4"
        />
        <div
          ref={(el) => elementsToObserve.current.push(el)}
          className="transitiondiv"
        ></div>
      </div>
    </div>
  );
};

export default Section4;