import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import "./Page2.css";
import { useBase } from "../../contexts/BaseContext";



const Page2 = ({ setDateSelected }) => {
  const { selectedDate, setSelectedDate,getDisabledDates,disabledDates } = useBase();
  
  useEffect(() => {
    getDisabledDates();
}, []);
useEffect(() => {
 console.log(disabledDates)
}, [disabledDates]);
  const handleDateChange = (date) => {
    const adjustedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const day = adjustedDate.getUTCDay();
    const formattedDate = adjustedDate.toISOString().split('T')[0];
    if ((day === 3 || day === 5) && !disabledDates.includes(formattedDate)) {
      setSelectedDate(adjustedDate);
      setDateSelected(formattedDate); // Save date in yyyy-mm-dd format
    }
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      const adjustedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const today = new Date();
      const utcToday = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      const day = adjustedDate.getUTCDay();
      const formattedDate = adjustedDate.toISOString().split('T')[0];
  
      const fixedDate = new Date(Date.UTC(2024, 9, 18)); // Replace with your fixed date in UTC (e.g., December 31, 2023)
  
      // Disable dates before today, days that are not Wednesday or Friday, specific disabled dates, and dates before the fixed date
      if (
        adjustedDate < utcToday || 
        (day !== 3 && day !== 5) || 
        disabledDates.includes(formattedDate) ||
        adjustedDate < fixedDate
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="page page-enter">
      <h1 className="page-title">Kérem válasszon egy napot</h1>
      <Calendar
        onChange={handleDateChange}
        value={selectedDate ? new Date(selectedDate) : null}
        tileDisabled={tileDisabled}
      />
    </div>
  );
};

export default Page2;