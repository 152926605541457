import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import { useBase } from "../../contexts/BaseContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const pages = [<Page1 setServiceSelected={() => {}} />, <Page2 setDateSelected={() => {}} />, <Page3 />, <Page4 />];

const LandingPageAdmin = () => {
  const {pagesA,selectedService, setSelectedTime,firstOpen,setFirstOpen,nextPage,currentPage, setCurrentPage,isServiceSelected, setIsServiceSelected,isDateSelected, setIsDateSelected ,doneRes,setDoneRes} = useBase();

  
  useEffect(() => {
    if (doneRes === "SUCCESS-NOPAY") {
      setDoneRes("");
      toast.success('Sikeres Időpont Foglalás!');
    } else if (doneRes === "SUCCESS-PAY") {
      setDoneRes("");
      toast.success('Sikeres Fizetés!');
      toast.success('Sikeres Időpont Foglalás!');
    } else if (doneRes === "FAIL-NOPAY") {
      setDoneRes("");
      toast.error('Sikertelen Időpont Foglalás!');
    } else if (doneRes === "FAIL-PAY") {
      setDoneRes("");
      toast.error('Sikertelen Fizetés!');
      toast.error('Sikertelen Időpont Foglalás!');
    }
}, [doneRes]);

  const setPage = (pageIndex) => {
    if (pageIndex <= currentPage) {
      setCurrentPage(pageIndex);
      setFirstOpen(true);
    }
  };

  const updatePage = () => {
    return pagesA.map((page, index) => {
      if (index === 0) {
        return React.cloneElement(page, { setServiceSelected: setIsServiceSelected });
      } else if (index === 1) {
        return React.cloneElement(page, { setDateSelected: setIsDateSelected });
      } else if (index === 2) {
        return React.cloneElement(page, { setTimeSelected: setSelectedTime });
      } else {
        return page;
      }
    })[currentPage];
  };

  return (
    <>
    <div className="landing-page">
      <ProgressBar currentPage={currentPage} setPage={setPage} totalPages={pagesA.length} />
      <div className="page-container">
        {updatePage()}
        {currentPage < pagesA.length - 1 && (
          <button
            className="next-button"
            onClick={nextPage}
            disabled={currentPage === 0 ? selectedService === null || selectedService === "" : currentPage === 1 ? !isDateSelected : false}
            style={{display:currentPage === 2 ? "none" : "inline"}}
          >
            {currentPage >= pagesA.length - 1 ? "Foglalás" : "Következő"}
          </button>
        )}
      </div>
    </div>
    <ToastContainer position="bottom-left"/>
    </>
  );
};

export default LandingPageAdmin;