import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { useBase } from '../../contexts/BaseContext';
import { useAdmin } from './AdminContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminList = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [reservations, setReservations] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentReservation, setCurrentReservation] = useState(null);
    const [openedDate, setOpenedDate] = useState(null);
    const [openedTime, setOpenedTime] = useState(null);
    const [openedTime2, setOpenedTime2] = useState(null);
    const [emptyModal, setEmptyModal] = useState(false);
    const [occupiedSlotsCount, setOccupiedSlotsCount] = useState(0);
    const [modalState, setModalState] = useState(0);
    const [freeString,setFreeString] = useState(0);
    const [isLockedDay,setIsLockedDay] = useState(false);
    const {selectedService,setSelectedService,doneRes,setDoneRes,
        name, setName,
    phone, setPhone,
    email, setEmail,
    country, setCountry,
    city, setCity,
    postalCode, setPostalCode,
    address, setAddress,
    taj, setTaj,
    paymentMethod, setPaymentMethod,makeReservationAdmin,deleteRes
    } = useBase();
    const {intNum,setIntNum,before,after,getAfter,getBefore} = useAdmin();

    useEffect(() => {
        if (doneRes === "SUCCESS-NOPAY") {
          setDoneRes("");
          toast.success('Sikeres Időpont Foglalás!');
          fetchReservations(selectedDate);
        } else if (doneRes === "SUCCESS-PAY") {
          setDoneRes("");
          toast.success('Sikeres Fizetés!');
          toast.success('Sikeres Időpont Foglalás!');
          fetchReservations(selectedDate);
        } else if (doneRes === "FAIL-NOPAY") {
          setDoneRes("");
          toast.error('Sikertelen Időpont Foglalás!');
          fetchReservations(selectedDate);
        } else if (doneRes === "FAIL-PAY") {
          setDoneRes("");
          toast.error('Sikertelen Fizetés!');
          toast.error('Sikertelen Időpont Foglalás!');
          fetchReservations(selectedDate);
        }
    }, [doneRes]);

    const getFreeTimeSlotsString = (reservations) => {
        const timeSlots = [
            '08:00', '08:10', '08:20', '08:30', '08:40', '08:50',
            '09:00', '09:10', '09:20', '09:30', '09:40', '09:50',
            '10:00', '10:10', '10:20', '10:30', '10:40', '10:50',
            '11:00', '11:10', '11:20', '11:30', '11:40', '11:50',
            '12:00', '12:10', '12:20', '12:30', '12:40', '12:50',
            '13:00', '13:10', '13:20', '13:30', '13:40', '13:50',
            '14:00', '14:10', '14:20', '14:30', '14:40', '14:50',
            '15:00', '15:10', '15:20', '15:30', '15:40', '15:50',
            '16:00', '16:10', '16:20', '16:30', '16:40', '16:50',
            '17:00', '17:10', '17:20', '17:30', '17:40', '17:50',
            '18:00', '18:10', '18:20', '18:30', '18:40', '18:50',
            '19:00', '19:10', '19:20', '19:30', '19:40', '19:50',
            '20:00'
        ];
    
        const freeSlots = timeSlots.filter(time => {
            const reservation = getReservationForTimeSlot(time,reservations);
            return !(reservation && reservation.id > 0);
        });
    
        const ret = freeSlots.join(' - ');
        setFreeString(ret);
    };

    useEffect(() => {
        fetchReservations(selectedDate);
        getlockDay();
    }, [selectedDate]);

    const fetchReservations = (date) => {
        const formattedDate = new Date(date);
        formattedDate.setDate(formattedDate.getDate() + 0);
        const dateString = formattedDate.toISOString().split('T')[0];

        axios.get(`https://katadent.hu:41806/admin/getRes?date=${dateString}`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            setReservations(response.data);
            countOccupiedSlots(response.data);
            getFreeTimeSlotsString(response.data);
          })
          .catch(error => {
            if (error.response) {
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                console.error('Payment Error:', error.request);
            } else {
                console.error('Error', error.message);
            }
          });
          getAfter(dateString);
          getBefore(dateString);
    };
    const countOccupiedSlots = (reservations) => {
        let count = 0;
        const timeSlots = Object.keys(reservations);
        timeSlots.forEach(time => {
            if (reservations[time] && reservations[time].id > 0) {
                count++;
            }
        });
        setOccupiedSlotsCount(count);
    };

    const openModal = (reservation,time,emMod) => {
        setCurrentReservation(reservation);
        setModalIsOpen(true);
        const formattedDate = new Date(selectedDate);
        formattedDate.setDate(formattedDate.getDate());
        const dateString = formattedDate.toISOString().split('T')[0];
        setOpenedDate(dateString);
        setOpenedTime(time);
        setEmptyModal(emMod);
        setModalState(0);
        setOpenedTime2(getTimeTwo(time));
    };
    const openModal2 = (state) => {
        //setCurrentReservation(reservation);
        setModalIsOpen(true);
        setModalState(state);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentReservation(null);
    };

    const getTimeTwo = (time) => {
        if (time === "08:00") {return "8:00";}
        if (time === "08:10") {return "8:10";}
        if (time === "08:20") {return "8:20";}
        if (time === "08:30") {return "8:30";}
        if (time === "08:40") {return "8:40";}
        if (time === "08:50") {return "8:50";}
        if (time === "09:00") {return "9:00";}
        if (time === "09:10") {return "9:10";}
        if (time === "09:20") {return "9:20";}
        if (time === "09:30") {return "9:30";}
        if (time === "09:40") {return "9:40";}
        return time;
    }

    const getReservationForTimeSlot = (time,reservations) => {
        switch (time) {
            case '08:00': return reservations._8_00;
            case '08:10': return reservations._8_10;
            case '08:20': return reservations._8_20;
            case '08:30': return reservations._8_30;
            case '08:40': return reservations._8_40;
            case '08:50': return reservations._8_50;
            case '09:00': return reservations._9_00;
            case '09:10': return reservations._9_10;
            case '09:20': return reservations._9_20;
            case '09:30': return reservations._9_30;
            case '09:40': return reservations._9_40;
            case '09:50': return reservations._9_50;
            case '10:00': return reservations._10_00;
            case '10:10': return reservations._10_10;
            case '10:20': return reservations._10_20;
            case '10:30': return reservations._10_30;
            case '10:40': return reservations._10_40;
            case '10:50': return reservations._10_50;
            case '11:00': return reservations._11_00;
            case '11:10': return reservations._11_10;
            case '11:20': return reservations._11_20;
            case '11:30': return reservations._11_30;
            case '11:40': return reservations._11_40;
            case '11:50': return reservations._11_50;
            case '12:00': return reservations._12_00;
            case '12:10': return reservations._12_10;
            case '12:20': return reservations._12_20;
            case '12:30': return reservations._12_30;
            case '12:40': return reservations._12_40;
            case '12:50': return reservations._12_50;
            case '13:00': return reservations._13_00;
            case '13:10': return reservations._13_10;
            case '13:20': return reservations._13_20;
            case '13:30': return reservations._13_30;
            case '13:40': return reservations._13_40;
            case '13:50': return reservations._13_50;
            case '14:00': return reservations._14_00;
            case '14:10': return reservations._14_10;
            case '14:20': return reservations._14_20;
            case '14:30': return reservations._14_30;
            case '14:40': return reservations._14_40;
            case '14:50': return reservations._14_50;
            case '15:00': return reservations._15_00;
            case '15:10': return reservations._15_10;
            case '15:20': return reservations._15_20;
            case '15:30': return reservations._15_30;
            case '15:40': return reservations._15_40;
            case '15:50': return reservations._15_50;
            case '16:00': return reservations._16_00;
            case '16:10': return reservations._16_10;
            case '16:20': return reservations._16_20;
            case '16:30': return reservations._16_30;
            case '16:40': return reservations._16_40;
            case '16:50': return reservations._16_50;
            case '17:00': return reservations._17_00;
            case '17:10': return reservations._17_10;
            case '17:20': return reservations._17_20;
            case '17:30': return reservations._17_30;
            case '17:40': return reservations._17_40;
            case '17:50': return reservations._17_50;
            case '18:00': return reservations._18_00;
            case '18:10': return reservations._18_10;
            case '18:20': return reservations._18_20;
            case '18:30': return reservations._18_30;
            case '18:40': return reservations._18_40;
            case '18:50': return reservations._18_50;
            case '19:00': return reservations._19_00;
            case '19:10': return reservations._19_10;
            case '19:20': return reservations._19_20;
            case '19:30': return reservations._19_30;
            case '19:40': return reservations._19_40;
            case '19:50': return reservations._19_50;
            case '20:00': return reservations._20_00;
            default: return null;
        }
    };

    const renderTimeSlots = (hour) => {
        const slots = [];
        for (let minute = 0; minute < 60; minute += 10) {
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const reservation = getReservationForTimeSlot(time,reservations);
            let emMod = false;
            if (reservation && reservation.id > 0) {
                emMod = true;
            }
            if (time !== "20:10" && time !== "20:20" && time != "20:30" && time!== "20:40" && time!= "20:50") {
                const matchingBefore = before.filter(item => item.time === time);
                const matchingAfter = after.filter(item => item.time === time);
                
                slots.push(
                    <div key={time} className="time-slotA" onClick={() => openModal(reservation, time, emMod)} style={{backgroundColor: reservation && reservation.id > 0 ? "#3f7af6" : "grey"}}>
                        {matchingBefore.map((item, index) => (
                                <>
                                ({item.name} - {item.kezeles})
                                <br/>
                                </>
                                
                        ))}
                        {time} : {reservation?.name || "-----"}
                        
                        {matchingAfter.map((item, index) => (
                                <>
                                <br /> ({item.name} - {item.kezeles})
                                </>
                        ))}
                    </div>
                );
            }
        }
        if (hour === 20) {
        slots.push(
            <div key={"Korábbiak"} className="time-slotA" onClick={() => openModal2(1)} style={{backgroundColor:"#3f7af6" }}>
                {"Korábbiak"}
            </div>
            );
        slots.push(
            <div key={"Késöbbiek"} className="time-slotA" onClick={() => openModal2(2)} style={{backgroundColor:"#3f7af6" }}>
                {"Későbbiek"}
            </div>
            );
        slots.push(
            <div key={"Szabad"} className="time-slotA" onClick={() => openModal2(3)} style={{backgroundColor:"#3f7af6" }}>
                {"73 / "} {occupiedSlotsCount}
            </div>
            );
        slots.push(
            <div key={"SzabadInterv"} className="time-slotA" onClick={() => openModal2(4)} style={{backgroundColor:"#3f7af6" }}>
                {"Szabad Időpontok"}
            </div>
            );
        slots.push(
            <div key={"Info"} className="time-slotA" onClick={() => openModal2(5)} style={{backgroundColor:"#3f7af6" }}>
                {"Napi Infó"}
            </div>
            );
        }
        return slots;
    };
    const getlockDay = async () => {
        const dateStr = new Date(selectedDate).toISOString().split('T')[0];
        axios.get(`https://katadent.hu:41806/admin/getIsLockDay?date=${dateStr}`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            setIsLockedDay(response.data);
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    };

    const lockDay = async () => {
        const dateStr = new Date(selectedDate).toISOString().split('T')[0];
        axios.get(`https://katadent.hu:41806/admin/lockDay?date=${dateStr}`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            toast.error(dateStr+' Lezárva!');
            getlockDay();
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    };
    const unlockDay = async () => {
        const dateStr = new Date(selectedDate).toISOString().split('T')[0];
        axios.get(`https://katadent.hu:41806/admin/unlockDay?date=${dateStr}`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            toast.success(dateStr+' Feloldva!');
            getlockDay();
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    };

    return (
        <>
        <div className="reservations-container">
            <div className="dateSECTION-HOLDER">
                {isLockedDay ? 
                <button className='settings-submit-button2' onClick={()=>lockDay()}>Nap Lezárása</button>
                :
                <button className='settings-submit-button2-dis' disabled onClick={()=>lockDay()}>Nap Lezárása</button>
                }
                <div className="datepicker-container">
                    <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        dateFormat="yyyy/MM/dd"
                        className="datepicker"
                    />
                </div>
                {!isLockedDay ?
                <button className='settings-submit-button2' onClick={()=>unlockDay()} >Nap Feloldása</button>
                :
                <button className='settings-submit-button2-dis' disabled onClick={()=>unlockDay()} >Nap Feloldása</button>
                }
            </div>
            <div className="time-slots-wrapper">
                {Array.from({ length: 13 }, (_, i) => 8 + i).map(hour => (<>
                    
                    <div key={hour} className="hour-column">
                        <div key={hour} className="hour-label">
                            {hour}
                        </div>
                        {renderTimeSlots(hour)}
                    </div></>
                ))}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Reservation Details"
                className="modal"
                overlayClassName="modal-overlay"
            >
                {currentReservation && emptyModal ? (
                    <div className="reservation-details">
                        <h2>Foglalási adatok</h2>
                        <p><strong>Név:</strong> {currentReservation.name}</p>
                        <p><strong>Időpont:</strong> {currentReservation.resDate} - {currentReservation.resStartIntervall}</p>
                        <p><strong>Kezelés:</strong> {currentReservation.resAllIntervall}</p>
                        {currentReservation.phone !== "" && <p><strong>Telefonszám:</strong> {currentReservation.phone}</p>}
                        {currentReservation.email !== "" && <p><strong>E-mail:</strong> {currentReservation.email}</p>}
                        {currentReservation.country !== "" && <p><strong>Ország:</strong> {currentReservation.country}</p>}
                        {currentReservation.city !== "" && <p><strong>Város:</strong> {currentReservation.city}</p>}
                        {currentReservation.postalCode !== "" && <p><strong>Irányítószám:</strong> {currentReservation.postalCode}</p>}
                        {currentReservation.address !== "" && <p><strong>Cím:</strong> {currentReservation.address}</p>}
                        {currentReservation.tajNum !== "" && <p><strong>TAJ szám:</strong> {currentReservation.tajNum}</p>}
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
                        <button onClick={()=>deleteRes(currentReservation.id)} className="close-button">Törlés</button>
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                        </div>
                    </div>
                )
                : modalState === 0 ? 
                (
                    <div className="reservation-details">
                        <h2>Nincs foglalás az időpontra</h2>
                        <div className="inputs-container">
                            <div className="input-group">
                            <label>Név</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <ul className="service-list">
                            <li className="service-item">
                                <label>
                                <input
                                    type="text"
                                    name="service"
                                    value={selectedService}
                                    onChange={(e) => setSelectedService(e.target.value)}
                                />
                                <input
                                    type="number"
                                    name="serviceNum"
                                    min="1"
                                    max="3"
                                    value={intNum}
                                    onChange={(e) => setIntNum(e.target.value)}
                                />
                                </label>
                            </li>
                        </ul>
                            <div className="input-group">
                            <label>Telefonszám</label>
                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>Email</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>Ország</label>
                            <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>Város</label>
                            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>Irányítószám</label>
                            <input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>Cím</label>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="input-group">
                            <label>TAJ szám (opcionális)</label>
                            <input type="text" value={taj} onChange={(e) => setTaj(e.target.value)} />
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
                        <button onClick={()=>makeReservationAdmin(intNum,selectedDate,openedTime2)} className="close-button">Foglalás</button>
                        <button onClick={closeModal} className="close-button">Close</button>
                        </div>
                    </div>
                )
                : modalState === 1 ?
                (
                    <div className="reservation-details">
                        <h2>Korábbiak</h2>
                        {(modalState === 1 ? before : after).map((reservation, index) => (
                            <div key={index} className="modreservation-item">
                            <span className="modreservation-time">{reservation.time}</span>
                            <span className="modreservation-treatment">{reservation.kezeles}</span>
                            <span className="modreservation-name">{reservation.name}</span>
                            </div>
                        ))}
                        <button onClick={closeModal} className="close-button">Close</button>
                    </div>
                )
                : modalState === 2 ?
                (
                    <div className="reservation-details">
                        <h2>Későbbiek</h2>
                        {(modalState === 1 ? before : after).map((reservation, index) => (
                            <div key={index} className="modreservation-item">
                            <span className="modreservation-time">{reservation.time}</span>
                            <span className="modreservation-treatment">{reservation.kezeles}</span>
                            <span className="modreservation-name">{reservation.name}</span>
                            </div>
                        ))}
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                    </div>
                )
                : modalState === 3 ?
                (
                    <div className="reservation-details" style={{alignItems:"center"}}>
                        <h2>Napi foglalási arány</h2>
                        <h2>(Összes) {"73 / "} {occupiedSlotsCount} (Foglalt)</h2>
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                    </div>
                )
                : modalState === 4 ?
                (
                    <div className="reservation-details">
                        <h2>Szabad időpontok</h2>
                        <p>{freeString}</p>
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                    </div>
                )
                : modalState === 5 ?
                (
                    <div className="reservation-details">
                        <h2>Információ</h2>
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                    </div>
                )
                :
                (
                    <div className="reservation-details">
                        <h2>Nincs foglalás az időpontra</h2>
                        <button onClick={closeModal} className="close-button">Bezárás</button>
                    </div>
                )
                }
            </Modal>
        </div>
        <ToastContainer position="bottom-left"/>
    </>
    );
};

export default AdminList;