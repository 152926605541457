import React, { useEffect, useRef } from "react";

const Section3 = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="section-3">
      <div className="section3container">
        <h1
          ref={(el) => elementsToObserve.current.push(el)}
          className="section2heading"
        >
          Legyen úgy kezelve, mint egy ember
          <br />
          és nem úgy mint egy páciens.
        </h1>
        <div
          ref={(el) => elementsToObserve.current.push(el)}
          className="section2content"
        >
          <div className="section2bal">
            <img loading="lazy" src="images/szivecske.png" alt="" className="section2balkep" />
            <h1 className="section2balheading">Biztonságos hely<br />Mindenki számára</h1>
            <p className="section2baltext">Egy ítéletmentes zóna, ahol mindig otthon érezheti magát.</p>
          </div>
          <div className="section2mid">
            <img loading="lazy" src="images/villam.png" alt="" className="section2midkep" />
            <h1 className="section2midheading">Villámgyors<br />foglalás</h1>
            <p className="section2midtext">Megtaláljuk a megfelelő időpontot, hogy könnyen aludhasson ma este.</p>
          </div>
          <div className="section2jobb">
            <img loading="lazy" src="images/csillag.png" alt="" className="section2jobbkep" />
            <h1 className="section2jobbheading">Csodálatos ember van <br />a mosoly mögött</h1>
            <p className="section2jobbtext">És nagyon szeretünk mindent megtudni róluk.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;