import React, { useEffect, useRef } from "react";

const FooterSzolg = () => {
    const elementsToObserve = useRef([]);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };
  
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          } else {
            //entry.target.classList.remove("in-view");
          }
        });
      };
  
      const observer = new IntersectionObserver(callback, options);
  
      elementsToObserve.current.forEach((el) => {
        if (el) observer.observe(el);
      });
  
      return () => {
        elementsToObserve.current.forEach((el) => {
          if (el) observer.unobserve(el);
        });
      };
    }, []);

  return (
    <div>
        <div data-w-id="ecadde35-1101-f81c-914e-1fa0499692fd" ref={(el) => elementsToObserve.current.push(el)} class="footer-section">
      <div class="footercontainer">
        <div class="footerlogo"><img src="images/dentistry_100dp_3F7AF6_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-9"/>
          <h1 class="heading-30">Katadent</h1>
        </div>
        <div class="footercontent">
          <div class="footerjobb">
            <div class="footerjobb1">
              <a data-w-id="ecadde35-1101-f81c-914e-1fa049969306"  href="/" class="link-5">Kezdőlap</a>
              <a data-w-id="ecadde35-1101-f81c-914e-1fa049969308"  href="/rolunk" class="link-10">Rólunk</a>
              <a data-w-id="ecadde35-1101-f81c-914e-1fa04996930a"  href="/szolgaltatasok" aria-current="page" class="link-10 w--current">Szolgáltatásaink</a>
            </div>
            <div class="location">
              <p class="text-block-41">8800 Nagykanizsa <br/>Szent Imre utca 5.</p>
            </div>
            <div class="nyitvatartas">
              <p class="text-block-12">Péntekenként: 14-19<br/>Két hetente szerda: <br/>9-13</p>
            </div>
          </div>
          <div class="footerbal">
            <p class="kapcsolat">Vegye fel velünk a kapcsolatot!</p>
            <a href="mailto:info@katadent.hu" class="emaillink w-inline-block"><img loading="lazy" src="images/mail_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" alt="" class="image-5"/></a>
            <p class="hivjonminket">Hívjon minket</p>
            <p class="telcsofooter">(+36)70/628-2159</p>
          </div>
        </div>
        <div class="footercontent2">
          <div class="footercontent2links">
            <a data-w-id="ecadde35-1101-f81c-914e-1fa049969323"  href="/impresszum" class="link-9">Impresszum</a>
            <a data-w-id="ecadde35-1101-f81c-914e-1fa049969325"  href="https://www.katadent.hu/docs/adatvedelmi-szabalyzat.pdf" target="_blank" class="link-10">Adatvédelmi szabályzat</a>
            <a data-w-id="ecadde35-1101-f81c-914e-1fa049969327"  href="https://www.katadent.hu/docs/adatkezelesi-tajekoztato.pdf" target="_blank" class="link-10">Adatvédelmi tájékoztató</a>
            <a data-w-id="ecadde35-1101-f81c-914e-1fa049969329"  href="/aszf" class="link-10">ÁSZF</a>
          </div>
          <div class="footertextholder">
            <p class="text-block-13">Bármilyen sebészeti vagy invazív beavatkozás kockázatokkal jár. Mielőtt folytatná, kérjen másodvéleményt egy megfelelően képzett sebésztől.</p>
          </div>
        </div>
        <div class="footerlicensz">
          <p class="text-block-14">GMA Coproration © 2024</p>
          <p class="text-block-15">Minden jog fenntartva.</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FooterSzolg;