import React, { useEffect, useRef } from "react";

const Section2 = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div>
      <div data-w-id="12e855ea-f11f-6b85-83d4-6b76450f79e9" className="section-2-copy">
        <div className="miertmijobb">
          <div className="contentholder">
            <div
              ref={(el) => elementsToObserve.current.push(el)}
              className="miertmitextholder"
            >
              <div className="miertmitext">Miért mi?</div>
              <div className="miertmicsik"></div>
            </div>
            <h1
              ref={(el) => elementsToObserve.current.push(el)}
              className="miertmiheading"
            >
              Az elégedettség a legfontosabb számunkra.
            </h1>
            <p
              ref={(el) => elementsToObserve.current.push(el)}
              className="miertmileiras"
            >
              Válassza a Katadentet, mert nálunk a szakértelem, a gondoskodás és a páciens elégedettsége az első!
            </p>
            <a
              ref={(el) => elementsToObserve.current.push(el)}
              href="/rolunk"
              className="miertmigomb w-button"
            >
              Miért mi?
            </a>
          </div>
          <img
            src="images/60e45fd11f81825879889f47_background-object-why-us.svg"
            loading="lazy"
            alt=""
            className="image-12"
          />
        </div>
        <div className="miertmibal">
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="transitiondiv"
          ></div>
          <img
            src="images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a.jpeg"
            loading="lazy"
            sizes="(max-width: 479px) 94vw, 100vw"
            srcSet="images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-500.jpeg 500w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-800.jpeg 800w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-1080.jpeg 1080w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-1600.jpeg 1600w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-2000.jpeg 2000w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-2600.jpeg 2600w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a-p-3200.jpeg 3200w, images/9F2202DB-5C29-4C91-AF9F-0E29CF54BF53_1_201_a.jpeg 4667w"
            alt=""
            className="miertmikep"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2;