import React, { useState } from "react";
import "./Page1.css";
import { useBase } from "../../contexts/BaseContext";
import { useAdmin } from "./AdminContext";

const services = [
  { id: 1, name: "Konzultáció (10p)" },
  { id: 2, name: "Fogszabályozás konzultáció (10p)" },
  { id: 3, name: "Fogkő leszedés (20p)" },
  { id: 4, name: "Gyógyszer rendelés (10p)" },
  { id: 5, name: "Tejfog vizsgálat (10p)" },
  { id: 6, name: "Fogékszer ragasztás (10p)" },
  { id: 7, name: "Fogszabályozás kontroll (10p)" },
];

const Page1 = ({ setServiceSelected }) => {
  const {selectedService,setSelectedService,getKezLenghtA} = useBase()
  
  const {intNum,setIntNum} = useAdmin();
  

  const handleSelect = (service) => {
    setSelectedService(service);
    setServiceSelected(true); // Notify the parent component that a service has been selected
    const num = getKezLenghtA(service);
    setIntNum(num);
  };

  return (
    <div className="page page-enter">
      <h1 className="page-title">Kérem válasszon a szolgáltatások közül</h1>
      <ul className="service-list">
        {services.map((service) => (
          <li key={service.id} className="service-item">
            <label>
              <input
                type="radio"
                name="service"
                value={service.name}
                checked={selectedService === service.name}
                onChange={() => handleSelect(service.name)}
              />
              {service.name}
            </label>
          </li>
        ))}
        <li className="service-item">
            <label>
              <input
                type="text"
                name="service"
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
              />
              <input
                type="number"
                name="serviceNum"
                min="1"
                max="3"
                value={intNum}
                onChange={(e) => setIntNum(e.target.value)}
              />
            </label>
          </li>
      </ul>
    </div>
  );
};

export default Page1;