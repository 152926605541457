import React, { useEffect, useRef } from "react";

const FooterImp = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="footer-section" ref={(el) => elementsToObserve.current.push(el)} >
      <div className="footercontainer">
        <div className="footerlogo">
          <img src="images/dentistry_100dp_3F7AF6_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" className="image-9" />
          <h1 className="heading-30">Katadent</h1>
        </div>
        <div className="footercontent">
          <div className="footerjobb">
            <div className="footerjobb1">
              <a href="/" className="link-5" >Kezdőlap</a>
              <a href="/rolun" className="link-10" >Rólunk</a>
              <a href="/szolgaltatasok" className="link-10" >Szolgáltatásaink</a>
            </div>
            <div className="location">
              <p className="text-block-41">8800 Nagykanizsa <br />Szent Imre utca 5.</p>
            </div>
            <div className="nyitvatartas">
              <p className="text-block-12">Péntekenként: 14-19<br />Két hetente szerda: <br />9-13</p>
            </div>
          </div>
          <div className="footerbal">
            <p className="kapcsolat">Vegye fel velünk a kapcsolatot!</p>
            <a href="mailto:info@katadent.hu" className="emaillink w-inline-block">
              <img loading="lazy" src="images/mail_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" alt="" className="image-5" />
            </a>
            <p className="hivjonminket">Hívjon minket</p>
            <p className="telcsofooter">(+36)70/628-2159</p>
          </div>
        </div>
        <div className="footercontent2">
          <div className="footercontent2links">
            <a href="/impresszum" className="link-9" >Impresszum</a>
            <a href="https://www.katadent.hu/docs/adatvedelmi-szabalyzat.pdf" target="_blank" className="link-10" >Adatvédelmi szabályzat</a>
            <a href="https://www.katadent.hu/docs/adatkezelesi-tajekoztato.pdf" target="_blank" className="link-10" >Adatvédelmi tájékoztató</a>
            <a href="/aszf" className="link-10" >ÁSZF</a>
          </div>
          <div className="footertextholder">
            <p className="text-block-13">Bármilyen sebészeti vagy invazív beavatkozás kockázatokkal jár. Mielőtt folytatná, kérjen másodvéleményt egy megfelelően képzett sebésztől.</p>
          </div>
        </div>
        <div className="footerlicensz">
          <p className="text-block-14">GMA Corporation © 2024</p>
          <p className="text-block-15">Minden jog fenntartva.</p>
        </div>
      </div>
    </div>
  );
};

export default FooterImp;